import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { styled } from 'twin.macro';
import { SHOW_TYPE } from '../constants';
import { Box } from '@material-ui/core';
import { getLinkUpgradeToWatch } from '@/core/method/contentMethod';
import { getMinTier, packageAdjacent } from '@/core/method/subScriptionPlanMethod';
import _ from 'lodash';
import { getConfigByKey } from '@/shared/features/tenantConfig/services';
import UpgradeGuideline from './UpgradeGuideline';

const PopupUpgradeStyled = styled(Modal)`
  top: 22%;
  .modal-content {
    position: relative;
    background-image: url(${require('@/assets/img/sctv/ppv-app-background.png')});
    background-repeat: no-repeat;
    background-size: cover;
    &:before {
      content: '';
      display: block;
      /* padding-top: calc(385 / 615 * 100%); */
    }
  }
  &.iap {
    .modal-content {
      &:before {
        /* padding-top: calc(532 / 615 * 100%); */
      }
    }
  }
  .modal-header {
    padding: 0;
  }

  .modal-body {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .close {
    font-weight: 300;
    color: #121212;
    opacity: 1;
    font-size: 28px;
    line-height: 20px;
    padding: 14px;
  }

  .wrap {
    /* position: absolute; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 767px) {
    .close {
      padding: 10px;
    }
    .modal-body {
      padding: 0 20px;
    }
    .modal-content {
      &:before {
        /* padding-top: calc(272 / 395 * 100%); */
      }
    }
  }
`;

class PopupUpgrade extends Component {
  static propTypes = {
    modalShow: PropTypes.bool,
  };

  _hideModel() {
    const { hideModal } = this.props;
    hideModal('modalShow');
  }

  hrefUrl() {
    const { history, language } = this.props;
    const entity = this?.props?.entity ?? this?.state?.entity ?? {};

    let param = entity
      ? {
          upgrade: true,
          callbackUrl:
            entity.type === SHOW_TYPE
              ? `/landing/${entity.slug || entity.id}`
              : `/detail/${entity.slug || entity.id} `,
        }
      : { upgrade: true, callbackUrl: `/` };
    let tierPackage = null;

    const { min_sub_tier = {} } = entity;
    const requireMinTier = getMinTier.call({ sub_tier: min_sub_tier });

    tierPackage = requireMinTier?.tier;
    return history.push(getLinkUpgradeToWatch.call({ entity, tierPackage }), param);
  }

  _checkSubscriptionPlan() {
    const flagEnbTier = getConfigByKey('features.multiTierSubscriptionEnabled');
    const entity = this?.props?.state?.entity ?? this?.props?.entity ?? {};

    const { min_sub_tier = {} } = entity ?? {};
    const availableTiers = packageAdjacent();
    if (!flagEnbTier) {
      return 'VIP';
    }

    if (_.isEmpty(min_sub_tier)) {
      return availableTiers?.[0]?.name ?? '';
    }
    const { name, tier, is_private } = min_sub_tier;
    if (is_private) {
      const tempPackage = availableTiers.find(item => item.tier <= tier);
      return tempPackage?.name;
    }
    return name;
  }

  render() {
    const { modalShow, t, entity, account } = this.props;

    return (
      <PopupUpgradeStyled
        show={modalShow}
        onHide={() => this._hideModel()}
        aria-labelledby="contained-modal-title-vcenter"
        className={`v-popupUpgrade ${
          account?.profile?.subscription_plan_info?.current_payment_provider ?? 'iap'
        }`}
        centered
      >
        <div className="wrap">
          <Modal.Header closeButton style={{ border: 'none', paddingBottom: 0 }}></Modal.Header>
          <Modal.Body>
            <UpgradeGuideline
              _checkSubscriptionPlan={this._checkSubscriptionPlan.bind(this)}
              hrefUrl={this.hrefUrl.bind(this)}
              _hideModel={this._hideModel.bind(this)}
            />
          </Modal.Body>
        </div>
      </PopupUpgradeStyled>
    );
  }
}

PopupUpgrade.defaultProps = {
  hideModal: () => {},
  modalShow: false,
};
const mapStateToProps = state => ({
  account: state.auth.account,
  language: state.root.language,
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, null)(withTranslation()(PopupUpgrade)));
