import React from 'react';
import { Box } from '@material-ui/core';
import { Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { styled } from 'twin.macro';
import _ from 'lodash';
import { useSelector } from 'react-redux';

const DivStyled = styled.div`
  margin-bottom: 10%;
  &.v-alertPayment {
    padding: 0 calc(46 / 615 * 100%);

    .v-alertPayment {
      &--title {
        font-family: Open Sans;
        font-size: 29px;
        color: #283237;
        text-decoration: none solid rgb(40, 50, 55);
        text-align: center;
        padding: 0 calc(33 / 615 * 100%);
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 5%;
        @media (max-width: 678px) {
          font-size: 20px;
        }
      }
      &--description {
        font-family: Open Sans;
        font-size: 18px;
        color: #1e232a;
        text-decoration: none solid rgb(30, 35, 42);
        text-align: center;
        margin: 16px 0;
        padding: 0 calc(33 / 615 * 100%);
      }
      &--bold {
        font-weight: bold;
      }
      &--red {
        color: #e72d30;
        font-weight: bold;
      }
      &--btn {
        font-weight: bold;
        padding: 14px 0;
        border: 1px solid #fff;
        color: #fff;
        background: #e72d30;
        font-family: Noto Sans;
        font-size: 20px;
        width: 100%;
        border-radius: 10px;
        flex-basis: calc(50% - 5px);
        &:hover {
          border: 1px solid #e72d30;
        }
        &.cancel {
          background: #8c8c8c;
          border: 1px solid #8c8c8c;
          &:hover {
            border: 1px solid #8c8c8c;
          }
        }
      }
    }
    @media (max-width: 767px) {
      .v-alertPayment {
        &--title {
          font-size: 20px;
          padding: 0;
          margin: 0;
        }
        &--description {
          font-size: 14px;
          padding: 0;
        }
        &--btn {
          padding: 10px 0;
        }
      }
    }
  }
`;
export default function NormalGuideline(props) {
  const { _checkSubscriptionPlan, hrefUrl, _hideModel, displayCancelButton = true } = props;
  const profile = useSelector(state => state?.auth?.account?.profile ?? {});
  const { subscription_plan_info } = profile;

  const { t } = useTranslation();
  return (
    <DivStyled className="v-alertPayment">
      <h2 className="v-alertPayment--title">Nâng Cấp Tài Khoản</h2>
      {!_.isEmpty(profile) && (
        <Box className="v-alertPayment--description">
          Gói dịch vụ {` `}
          <span className="v-alertPayment--bold">
            {subscription_plan_info?.name ?? `SCTVOnline Basic`}
          </span>{' '}
          của bạn không hỗ trợ xem nội dung này.
        </Box>
      )}
      <Box className="v-alertPayment--description">
        Nội dung này chỉ hỗ trợ cho gói dịch vụ{' '}
        <span className="v-alertPayment--red">
          SCTVOnline {_.capitalize(_checkSubscriptionPlan())}
        </span>{' '}
        trở lên.
      </Box>
      <Box className="v-alertPayment--description">Vui lòng nâng cấp gói dịch vụ để xem.</Box>
      <Box className="actions" display={'flex'} justifyContent={'space-between'}>
        <Button className="v-alertPayment--btn" onClick={() => hrefUrl()}>
          {t('landingPage.alert.btnUpgrade')}
        </Button>
        <Button className="v-alertPayment--btn cancel" onClick={() => _hideModel()}>
          {t('landingPage.alert.btnCancel')}
        </Button>
      </Box>
    </DivStyled>
  );
}
