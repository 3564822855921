import React from 'react';
import { useSelector } from 'react-redux';

import IAPGuideline from './IAPGuideline';
import NormalGuideline from './NormalGuideline';

export default function UpgradeGuideline(props) {
  const profile = useSelector(state => state?.auth?.account?.profile);

  if (profile?.current_payment_provider === 'iap') {
    return <IAPGuideline {...props} />;
  }
  return <NormalGuideline {...props} />;
}
