import React from 'react';
import { Box } from '@material-ui/core';
import { Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { styled } from 'twin.macro';

export const IAPGuidelineStyled = styled.div`
  padding: 0px 12% 10%;
  .v-guideline {
    &--title {
      color: #283237;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 5%;
    }
    &--description {
      font-family: Open Sans;
      font-size: 18px;
      color: #1e232a;
      text-decoration: none solid rgb(30, 35, 42);
      text-align: center;
      margin-bottom: 4%;
    }
    &--bold {
      font-weight: bold;
    }
    &--red {
      color: #e72d30;
      font-weight: bold;
    }

    &--btn {
      background-color: #e72d30;
      width: 100%;
      border-radius: 10px;
      font-family: Noto Sans;
      font-size: 20px;
      color: #ffffff;
      text-decoration: none solid rgb(255, 255, 255);
      text-align: center;
      font-weight: bold;
      padding: 15px;
      font-weight: bold;
    }
  }
  .actions {
    padding-top: 12px;
  }
  @media (max-width: 767px) {
    padding: 0px 0px 10%;

    .v-guideline {
      &--title {
        font-size: 20px;
      }
      &--description {
        font-size: 14px;
      }
      &--btn {
        padding: 10px;
      }
    }
  }
`;

export default function IAPGuideline(props) {
  const { _checkSubscriptionPlan, hrefUrl, _hideModel } = props;
  const { subscription_plan_info } = useSelector(state => state?.auth?.account?.profile ?? {});
  const { subscription_from = '' } = subscription_plan_info;

  const formatDeviceName = () => {
    if (subscription_from === 'android') {
      return _.capitalize(subscription_from);
    }
    return _.upperCase(subscription_from).replace('I', 'i');
  };
  return (
    <IAPGuidelineStyled className="v-guideline">
      <h2 className="v-guideline--title">Thông Báo</h2>
      {!_.isEmpty(subscription_plan_info) && (
        <Box className="v-guideline--description">
          Gói dịch vụ{' '}
          <span className="v-guideline--bold">{subscription_plan_info?.name ?? ''}</span> của bạn
          không hỗ trợ <br /> xem nội dung này.
        </Box>
      )}

      <Box className="v-guideline--description">
        Nội dung này chỉ hỗ trợ cho gói dịch vụ <br />
        <span className="v-guideline--red">
          SCTVOnline {_.capitalize(_checkSubscriptionPlan())}
        </span>{' '}
        trở lên
      </Box>
      <Box className="v-guideline--description">
        Vui lòng nâng cấp gói dịch vụ bằng <br /> thiết bị {formatDeviceName()} (đang đăng ký gói)
        của bạn.
      </Box>
      <Box className="actions" display={'flex'} justifyContent={'space-between'}>
        <Button className="v-guideline--btn" onClick={() => _hideModel()}>
          OK
        </Button>
      </Box>
    </IAPGuidelineStyled>
  );
}
